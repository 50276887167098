export default {
    "dashboard": {
        "activeDashboardName": "{ name }",
        "addWidget": "Добавить виджет",
        "editTitle": "Редактировать рабочий стол",
        "addTitle": "Добавить рабочий стол",
        "name": "Название рабочего стола",
        "save": "Сохранить",
        "addNew": "Добавить рабочий стол",
        "fieldRequired": "Поле обязательно для заполнения",
        "requiredSym": "Не более { sym } символов",
        "updating": "Обновление",
        "updated": "Обновлено",
        "updateError": "Ошибка обновления",
        "pageNotFound": "Страница не найдена.",
        "confirmDelete": "Вы действительно хотите удалить рабочий стол?",
        "delete": "Удалить",
        "cancel": "Отмена",
        "updatedSuccessfully": "Рабочий стол \"{ name }\" обновлен",
        "createdSuccessfully": "Рабочий стол \"{ name }\" создан",
        "enter_title": "Введите название",
        "update_error": "Ошибка обновления",
        "dashboard_title": "Рабочий стол",
        "add_widget": "Добавить виджет",
        "edit_dashboard": "Редактировать рабочий стол",
        "add_dashboard": "Добавить рабочий стол",
        "desktop_name": "Название рабочего стола",
        "confirm_delete": "Вы действительно хотите удалить рабочий стол?",
        "updated_success": "Рабочий стол \"{ name }\" обновлен",
        "created_success": "Рабочий стол \"{ name }\" создан",
        "unpin": "Открепить",
        "pin": "Закрепить",
        "hide_mobile": "Скрыть в мобильной версии",
        "show_mobile": "Показать в мобильной версии",
        "rename_widget": "Переименовать виджет",
        "move_down": "Переместить вниз",
        "move_up": "Переместить вверх",
        "hide_pc": "Скрыть в пк версии",
        "show_pc": "Показать в пк версии",
        "widget_settings": "Настройки виджета",
        "reset": "Сбросить",
        "task": "Задача",
        "change": "Изменить",
        "select": "Выбрать",
        "order": {
            "create": "Оформить заказ",
            "previous_date": "Дата предыдущего заказа",
            "no_orders": "Заказы отсутствуют",
            "completed": "Завершено заказов",
            "in_progress": "Заказы в обработке"
        },
        "contractor": {
            "not_provided": "Не указан"
        },
        "user": {
            "last_seen": "Был в сети {date}",
            "last_seen_ago": "Был в сети {time}"
        },
        "no_widgets": "Нет виджетов",
        "close": "Закрыть",
        "drag_widget": "Перетащите виджет чтобы разместить его на рабочем столе",
        "done": "Готово",
        "search_placeholder": "Введите название",
        "all_widgets": "Все виджеты",
        "widget_categories": "Категории виджетов",
        "loading_update": "Обновление",
        "full_name": "ФИО",
        "first_inactive": "Сначала неактивные",
        "first_active": "Сначала активные",
        "activity": "Активность",
        "tasks": "Задач",
        "load_error": "Не удалось загрузить данные",
        "tasks_empty": "Задачи отсутствуют",
        "appeals_total": "Обращений за период",
        "no_appeals": "Нет обращений для отображения",
        "no_organizations": "Нет организаций для отображения",
        "higher_level": "На уровень выше",
        "show_organizations": "Показать организации",
        "show_appeals": "Показать обращения",
        "number": "Номер",
        "deadlineViolation": "Рассмотрено с нарушением срока",
        "GOReviewed": "Рассмотрено в ГО",
        "entraceSource": "Источники поступления",
        "appeals": "Зарегистрированные обращения",
        "projectsAbsent": "Проекты отсутствуют",
        "meetingsAbsent": "Собрания отсутствуют",
        "teamsAbsent": "Команды отсутствуют",
        "eventsAbsent": "События отсутствуют",
        "addEvent": "Добавить событие",
        "allDay": "Весь день",
        "appealsPeriod": "Обращений за период",
        "points": "балла",
        "toList": "К списку",
        "appeals": "Обращения",
        "backToList": "Назад к списку",
        "date": "Дата",
        "deadlineViolationAnalytics": "Рассмотрено с нарушением срока",
        "deadlineViolationTotal": "Рассмотрено с нарушением срока",
        "GOReviewedAnalytics": "Рассмотрено в ГО",
        "GOReviewedTotal": "Рассмотрено в ГО",
        "entraceSourceAnalytics": "Источники поступления",
        "entraceSourceTotal": "Источники поступления",
        "appealsAnalytics": "Зарегистрированные обращения",
        "commentEmptyMessage": "Для отображения комментариев необходимо установить связь",
        "settings": "Настройки",
        "totalAppeals": "Всего обращений",
        "entranceSources": "Источники поступления",
        "GOReviewed": "Рассмотрено в ГО",
        "deadlineViolation": "Рассмотрено с нарушением срока",
        "applications": "Заявления",
        "complaints": "Жалобы",
        "suggestions": "Предложения",
        "others": "Прочие (сообщение, запрос, отклик)",
        "entranceSourceAnalytics": "Источники поступления",
        "entranceSourceTotal": "Всего (источники поступления)",
        "appealsTotal": "Всего (обращения)",
        "noReportForms": "Для выбранной организации нет форм отчетности",
        "noFormF2GO": "Для выбранной организации нет формы отчетности Ф2ГО",
        "projectButton": "Проекты",
        "fundingButton": "Финансирование",
        "startDateLabel": "Дата начала проекта",
        "endDateLabel": "Дата завершения",
        "totalKZ": "Всего по Казахстану:",
        "progressText": "{name} - {value}{measure_unit}",
        "percent": "{percent} %",
        "noData": "Нет данных",
        "millionTenge": "млн.тг",
        "project": "проект",
        "projectGenitive": "проекта",
        "projects": "проектов",
        "ob_tg": "*объем финансирования, млн.тг",
        "start": "Начало",
        "end": "Конец",
        "parent_organization_placeholder": "Родительская организация",
        "no_data": "Нет данных",
        "select_options_error": "Не удалось получить результаты запроса",
        "select_options_fetch_error": "Не удалось получить опции селекта",
        "scroll_data_error": "Не удалось получить данные.",
        "parent_organization": "Родительская организация",
        "error_no_results": "Не удалось получить результаты запроса",
        "error_get_options": "Не удалось получить опции селекта",
        "error_fetching_data": "Не удалось получить данные.",
        "option_all": "Все",
        "option_self": "Собственные",
        "option_descendants": "Структурные подразделения",
        "legend_favorable": "принят благоприятный административный акт",
        "legend_burdensome": "принят обременительный административный акт",
        "legend_response": "дан ответ автору",
        "legend_terminated": "прекращено (ст. 89;70 АППК)",
        "legend_noted": "принято к сведению (ст. 89 АППК)",
        "legend_other": "другое",
        "regions": {
            "abai": "Абайская",
            "akmola": "Акмолинская",
            "aktobe": "Актюбинская",
            "almaty": "Алматинская",
            "almaty_city": "г.Алматы",
            "atyrau": "Атырауская",
            "jambyl": "Жамбылская",
            "ulytau": "Улытауская",
            "kostanay": "Костанайская",
            "kyzylorda": "Кызылординская",
            "mangystau": "Мангистауская",
            "north_kazakhstan": "СКО",
            "astana": "г.Астана",
            "pavlodar": "Павлодарская",
            "shymkent_city": "г.Шымкент",
            "turkestan": "Туркестанская",
            "west_kazakhstan": "ЗКО",
            "karaganda": "Карагандинская",
            "jetisu": "Жетысуская",
            "east_kazakhstan": "ВКО"
        }
    }
}